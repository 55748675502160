@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');

* {font-family: 'Rubik', sans-serif; color: #191c2f;}

.App {
  direction: rtl;
  margin: 0 auto;
  padding: 2rem;
}

body{
  background-size: 50px 50px;
  background-color: #e0e9ed;
  background-image: url('./images/wave.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  min-height: 100vh;
}

.wrapper {
  font-size: 22px;
  max-width: 600px;
  margin: 0 auto;
  background: #f2f8fb;
  border-radius: 40px;
  padding: 12px;
}

.footer {
  background: #f2f8fb;
  border-radius: 0 0  40px 40px;
  padding: 40px;
  max-width: 400px;
  margin: 0 auto;
}

h1 {
  font-size: 3.5rem;
  line-height: 3.5rem;
  margin: 0;
  margin-bottom: 2rem;
  
}

.btn {
  border: none;
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  padding: 10px 24px 12px;
  border-radius: 40px;
  background-color: #b08cf8;
  cursor: pointer;
  margin: 0 auto;
}

.btn:hover {
  background-color: #9b7ade;
}

.btnGreen {
  background-color: #41c300;
}

.btnGreen:hover {
  background-color: #36a200;
}

.btnRed {
  background-color: #fe6e75;
}

.btnRed:hover {
  background-color: #fd4b54;
}

.btn:disabled,
.btn[disabled]{
background-color: #dae8ed;
color: #aebfcf;
}

@media screen and (max-width: 600px) {

  .App {
    padding: 1rem;
  }

}