

.nextExerciseMessage {
    font-size: 1rem;
    line-height: 1rem;
    margin: 24px auto 0;
}

.settingWrapper {
    font-size: 1.2rem;
     text-align: right;
     margin: 32px 0 ;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    background: #dbe3e7;
    outline: none;
    border-radius: 40px;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 40px;
    background: #f0894f;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 40px;
    background: #f0894f;
    cursor: pointer;
}

.operatorWrapper {
    display: flex;
    gap: 32px;
}

.operatorContainer {
    display: block;
    position: relative;
    padding-right: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.operatorContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.operatorCheckmark {
    position: absolute;
    top: 2px;
    right: 0;
    height: 25px;
    width: 25px;
    background-color: #dbe3e7;
    border-radius: 40px;
}

.operatorContainer:hover input ~ .operatorCheckmark {
    background-color: #dbe3e7;
    border-radius: 40px;
}

.operatorContainer input:checked ~ .operatorCheckmark {
    background-color: #f0894f;
    border-radius: 40px;
}

.operatorCheckmark:after {
    content: "";
    position: absolute;
    display: none;
}

.operatorContainer input:checked ~ .operatorCheckmark:after {
    display: block;
}

.operatorContainer .operatorCheckmark:after {
    right: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid 1px white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.welcomeWrapper {
    background: #fff;
    border-radius: 30px;
    padding: 24px 24px 32px;
}

.whatsapp {
    width: 40px;
    height: 40px;
    mask-image: url('../../images/whatsapp.svg');
    -webkit-mask-image: url('../../images/whatsapp.svg');
    background-color: #7d909e;
    cursor: pointer;
}

.settings {
    width: 40px;
    height: 40px;
    mask-image: url('../../images/settings.svg');
    -webkit-mask-image: url('../../images/settings.svg');
    background-color: #7d909e;
    cursor: pointer;
}

.info {
    width: 40px;
    height: 40px;
    mask-image: url('../../images/info.svg');
    -webkit-mask-image: url('../../images/info.svg');
    background-color: #7d909e;
    cursor: pointer;
}

.home {
    width: 40px;
    height: 40px;
    mask-image: url('../../images/home.svg');
    -webkit-mask-image: url('../../images/home.svg');
    background-color: #7d909e;
    cursor: pointer;
}

.whatsapp:hover, .settings:hover, .info:hover, .home:hover {
    background-color: #acbfce; 
}

.welcomeFooter {
    display: flex;
    justify-content: space-evenly;
    margin: 24px 0 12px 0;
}

@media screen and (max-width: 600px) {
    .wrapper {
        font-size:  1.2rem;
    }

    .operatorWrapper {
        gap: 12px;
    }

    .settingWrapper {
         margin: 24px 0 ;
    }
}