.exerciseMainWrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    background: #fff;
    border-radius: 30px;
    padding: 24px;
}
.wrapperExercise {
    display: flex;
    font-size: 7rem;
    line-height: 7rem;
    gap: 6px;
    flex-flow: wrap;
}

.devider {
    height: 8px;
    background:  #000000;
    border-radius: 40px;
    width: 100%;
    margin: 12px 0 0 ;
}

.exerciseOperator {
    margin-right: auto;
}

.exerciseNumbers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.break {
    flex-basis: 100%;
    width: 0px; 
    height: 0px; 
    overflow: hidden;
}

.inputAnswer {
    width: 100%;
    background: none;
    font-size: 7rem;
    border: none;
    border-radius: 20px;
    background-color: #f2f8fb;
    margin-bottom: 12px;
    padding: 0;
}

.inputAnswer:hover {
    cursor: text;
    
}

.nextExerciseMessage {
    font-size: 18px;
    line-height: 18px;
    margin: 0 auto;
}

.canvasDraw {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
}

.progressbarBack {
    background-color: #dbe3e7;
    height: 6px;
    width: 50%;
    margin: 12px auto;
    display: flex;
    border-radius: 40px;
}

.progressbar {
    height: 6px;
    background-color: #f0884e;
    border-radius: 40px;
}

.exerciseTopMain {
    margin: 12px 0 24px;
    font-size: 18px;
    position: relative;
}

.exerciseTitle {
    flex-grow: 1;
    text-align: center;
}

.arrowLeft {
    width: 30px;
    height: 30px;
    mask-image: url('../../images/close.svg');
    -webkit-mask-image: url('../../images/close.svg');
    background-color: #7d909e;
    cursor: pointer;
    position: absolute;
    left: 6px;
    top: 8px;
}

.editMode {
    width: 30px;
    height: 30px;
    mask-image: url('../../images/draw.svg');
    -webkit-mask-image: url('../../images/draw.svg');
    background-color: #7d909e;
    cursor: pointer;
}

.editMode:hover, .arrowLeft:hover {
    background-color: #7d909e; 
}

.isEditView {
    opacity: 0.6;
}

.gameTimerIcon {
    width: 24px;
    height: 24px;
    mask-image: url('../../images/timer.svg');
    -webkit-mask-image: url('../../images/timer.svg');
    background-color: #7d909e;
    cursor: pointer;
}

.gameTimer {
    margin: 24px auto 12px;
    color: #7d909e;
    text-align: center;
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .wrapperExercise {
        font-size: 5rem;
        line-height: 5rem;
    }

    .inputAnswer {
        font-size: 5rem;
        margin-bottom: 6px;
    }

    .devider {
        height: 6px;
    }
}