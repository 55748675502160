.progressbarWrapper {
  direction:ltr; 
  white-space: nowrap;
  min-width: 160px;
}
  
.stepProgress {
  position: relative;
  padding-right: 45px;
  list-style: none;
}

.stepProgress::before  {
  display: inline-block;
  content: '';
  position: absolute;
  top: 0;
  right: 16px;
  width: 10px;
  height: calc(100% - 24px);
  border-right: 2px solid #CCC;
}
    
.stepProgressItem {
  position: relative;
  counter-increment: list;
  text-align: right;
  padding: 0 0 18px 0;
}
      
.stepProgressItem::before {
  display: inline-block;
  content: '';
  position: absolute;
  right: -29px;
  height: 100%;
  width: 10px;
}
      
.stepProgressItem::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  right: -40px;
  padding-top: 3px;
  width: 20px;
  height: 17px;
  border: 2px solid #CCC;
  border-radius: 50%;
  background-color: #FFF;
}
      
.isDone::before {
  border-right: 2px solid #27AE60;
}

.stepProgressItem:last-child:before {
  content: none;
}

.isDone::after {
  content: "✔";
  font-size: 12px;
  color: #FFF;
  text-align: center;
  border: 2px solid #27AE60;
  background-color: #27AE60;
}
      
      
.current::before {
  border-right: 2px solid #27AE60;
}
        
.current::after {
  content: counter(list);
  padding-top: 1px;
  width: 20px;
  height: 19px;
  top: -4px;
  right: -40px;
  font-size: 14px;
  text-align: center;
  color: #27AE60;
  border: 2px solid #27AE60;
  background-color: white;
}

@media screen and (max-width: 600px) {
  .progressbarWrapper {
    display: none;
  }
}
